@import './flex-fit.scss';
@import './variables.scss';
@import './../../components/Works/Works.scss';
@import './../../components/Header/Header.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';

.app {
    display: flex;
    flex-direction: column;
}


h2 {
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 28px;
}

.home-section {
    // scroll-padding-top: 4rem;
    padding: $reg-padding 0; 
    h2{
        padding: $reg-padding 0; 
        text-align: center;
    }

}

img{
    &.image-with-comment{
        margin-bottom: 1rem !important;
    }
    &.smaller-img{
        max-width: 900px !important;
    }
}

.figure {
    color: $grey;
    font-size: 13px !important;
    padding: 0 .35rem;
}

.contact{
    background: rgb(253, 246, 252);
    text-align: center;
}
iframe {
    z-index: 10000;
    position: relative;
}

.scroll-to-top {
    position: fixed;
    bottom: 2rem;
    right: 10rem;
    min-width: 0 !important;
    background-color: #FAFAFA;
    color: $primary-purple;
    margin: 1rem;
    cursor: pointer;
    border-radius: 50%;
    padding: 15px;
    width: 60px;
    height: auto;
    text-align: center;
    align-items: center;
    display: flex;
    &:hover {
        background-color: #7e00542a;
        color: white;
    }
}