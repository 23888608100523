$reg-padding: 4rem;

.works {
    // Your existing styles for the 'works' section
  padding: $reg-padding 0;
  text-align: center;
  .works-title{
    padding: $reg-padding 0;
  }
    .works-list {
      // Your existing styles for the 'works-list' container
      display:flex;
      justify-content: space-between;
    margin: auto;
    justify-content: center;
    justify-items: center;
    flex-direction: column;

    width: 60%;
    grid-template-columns: repeat(2, 1fr);
      .card-container {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 100px;
        justify-content: center;
       
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          // Your styles for the image
          width: 50%; // Adjust as needed
          height: auto; // Adjust as needed
          border-radius: 20px;
          max-width: 600px;

        }
        .text-container{
          margin: $reg-padding $reg-padding $reg-padding 100px;
          text-align: left;
          width: 100%;
          max-width: 600px;
        }

      }
  
   
    }
  }

.work-card-button {
  background-color: #7E0053;
  border-color: #7E0053;
  color: #ffffff;
  border-radius: 25px 25px 25px 25px;
  padding: 0.5rem 1.2rem;
  /* Add more styles as needed */

  &:hover {
      background-color: #6A0046; /* Adjust the hover background color */
      border-color: #6A0046; /* Adjust the hover border color */
      color: #ffffff; /* Adjust the hover text color */
      border-radius: 25px 25px 25px 25px;
      /* Add more hover styles as needed */
  }
}

// Works.scss

.case-study {
  font-size: 18px;
  color: #8F8F8F; /* Dark grey color */
  font-family: 'Open Sans', sans-serif; /* Use the Open Sans font */

}

