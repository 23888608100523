.footer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        margin: 1rem 0.5rem;
        height: 28px;
        width: auto;
    }
    .copyright{
        padding-bottom: 1rem;
    }
}