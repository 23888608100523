$grey: #767676;
$primary-purple: #7E0053;
$circle-size: 12px;
.sidebar-container {
    
  ul {
    list-style-type: none;
    text-align: right;
    margin-right: 1rem;
    padding: 0;
    li {
        margin-bottom: .75rem;
      a {
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 500;
        text-decoration: none;
        color: $grey;
        margin: 0.5rem;
        position: relative;
        &::after {
            content: '';
            width: $circle-size;
            height: $circle-size;
            border: 2px solid $grey;  // Border color for the unfilled circle
            border-radius: 50%;
            position: absolute;
            top: 60%;
            right: -20px;
            transform: translateY(-50%);
          }
        &:active, 
        &.active,
        &:hover {
          color: $primary-purple;
        font-weight: bold;
          &::after {
            content: '';
            width: $circle-size;
            height: $circle-size;
            background-color: $primary-purple;
            border-color: $primary-purple;
            border-radius: 50%;
            position: absolute;
            top: 60%;
            right: -20px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}
