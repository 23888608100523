/* FlexFit.scss */
$primary-purple: #7E0053;
$purple-bg: #FDF6FC;
$grey-bg: #FAFAFA;

.flex-fit, .trello, .spotify {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    .carousel {
        button {
            z-index: 2000;
        }

        .carousel-indicators {
            button {
                background-color: $primary-purple;
            }
        }

        .carousel-control-prev {
            justify-content: flex-start;

        }

        .carousel-control-next {
            justify-content: flex-end;

        }

        .carousel-control-prev-icon {

            background-image: url("../images//icon-arrow-left.svg") !important;
        }

        .carousel-control-next-icon {

            background-image: url("../images//icon-arrow-right.svg") !important;
        }

        .carousel-item img {
            height: 700px;
            object-fit: contain;
        }

    }




    .sidebar {
        width: 100%;
        position: fixed;
        top: 50%;
        right: 2rem;
        z-index: 100;
    }

    .purple-bg {
        background: $purple-bg;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2rem 0 4rem;

    }

    .lightblue-bg {
        background: #F3FBFF;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2rem 0 4rem;

    }

    .lightgreen-bg {
        background: #F1FFF6;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2rem 0 4rem;

    }
    .lightpink-bg {
        background: #FFF1F1;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2rem 0 4rem;

    }

    .grey-bg {
        background: $grey-bg;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 4rem;

        .text-container {

            h3 {
                &:first-of-type {
                    margin-top: 0;
                }
            }

        }
    }

    .work-hero {
        text-align: center;

        img {
            width: 50%;
        }
    }

    .work-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 2rem auto;
        }

        .image-outside {
            img {
                max-width: 70vw;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
       
        img {
            margin: 4rem 0;
            max-width: 100%;


        }

        .text-container {
            width: 900px;
            box-sizing: border-box;
            position: relative;

        }

        .full-w {
            position: relative;
            margin-top: 5rem;
            align-items: center;
            justify-content: center;
            width: 100%;
            display: flex;

            .work-body {
                width: 900px;
                box-sizing: border-box;
                position: relative;
                align-items: center;
                justify-content: center;
            }

        }

        h1 {
            font-family: 'Montserrat';
            font-weight: bold;
            font-size: 48px;
            padding: 3rem 0 0;
            text-align: left;
            width: 90%;
            margin: auto;
        }

        h2,
        h3,
        h4,
        ul,
        li,
        p,
        a,
        span {
            font-family: 'Open Sans';
        }

        p4 {
            margin: .5rem 0;
            font-size: 16px;
        }

        .section-title {
            scroll-margin-top: 10rem;
            color: $primary-purple;
            font-weight: bold;
            font-size: 48px;
            margin-top: 2rem;
        }

        h3 {
            font-size: 28px;
            font-weight: bold;
            margin: 4rem 0 1rem;

        }

        .team-member-container {
            margin-bottom: 3rem;
        }

        .team-member-name {
            color: $primary-purple;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
        }

        p {
            font-size: 18px;
        }

        #empathize,
        #define,
        #ideate,
        #prototype,
        #test,
        #implement {
            position: relative;
        }

    }
}

