// Hero.scss

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&family=Montserrat+Alternates:wght@700&display=swap');

.hero-video-container {
  position: relative;
  max-height: 800px; /* Adjust the max-height as needed */
  overflow: hidden; /* Hide any overflow beyond the max-height */
}

.hero-video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; // Set the text color to be visible on the video background
}

.hero-title {
  font-size: 48px;
  font-family: 'Montserrat Alternates', sans-serif; /* Apply Montserrat Alternates font */
  font-weight: 700;
  color: black;
}

.hero-subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #7E0053;
  font-family: 'Open Sans', sans-serif; /* Apply Open Sans font */
  margin-bottom: 100px; /* Adjust the margin-bottom as needed */
}

.hero-body {
  font-size: 20px;
  font-weight: 400;
  color: #7E0053;
  font-family: 'Montserrat Alternates', sans-serif; /* Apply Open Sans font */
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.hero-body {
  font-size: 20px;
  font-weight: 400;
  color: #7E0053;
  font-family: 'Montserrat Alternates', sans-serif; /* Apply Open Sans font */

  span {
    margin-right: 5px; /* Add margin between letters */
    display: inline-block;
    animation: wave 3s infinite alternate;
    animation-delay: calc(var(--i) * 0.5s);
  }
}