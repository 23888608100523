h1 {
    font-family: 'Montserrat', sans-serif;
}

.nav{
    font-family: 'Montserrat Alternates', sans-serif;
}

$grey: #919191;
$primary-purple: #7E0053;
$header-height: 84px;
$reg-padding: 4rem;