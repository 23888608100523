.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 3rem;
    position: sticky;
    background: white;
    width: 100%;
    z-index: 100000;
    top: 0;

    .nav ul {
        list-style-type: none;
        display: flex;

        a{
            text-decoration: none;
            color: $grey;
            font-size: 20px;
            padding-left: 38px; 
        }
        li.active a {
            color: $primary-purple; // Set the color for active nav item
            font-weight: 600;
          }
    }
}
